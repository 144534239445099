import { useHistory } from 'react-router-dom';

type HeaderLoginProps = {
  onClickLogout(): Promise<void>;
};

export const HeaderLogin = ({ onClickLogout }: HeaderLoginProps) => {
  const history = useHistory();

  const onClickMypage = () => {
    const { pathname } = history.location;
    if (pathname === '/mypage') return;

    let mypageUrl;

    if (process.env.NODE_ENV === 'development') {
      mypageUrl = 'http://localhost:3000';
      // redirectUrl = 'http://localhost:3001' + location.pathname;
    } else if (process.env.NODE_ENV === 'production') {
      mypageUrl = 'https://my.vrware.us';
      // redirectUrl = 'https://school.vrware.us' + location.pathname;
    }

    window.location.href = mypageUrl + '/mypage';
  };
  return (
    <div className='HeaderLogin'>
      <li onClick={onClickMypage}>
        <button className='login-button'>마이페이지</button>
      </li>
      <li onClick={onClickLogout}>
        <button className='login-button'>로그아웃</button>
      </li>
    </div>
  );
};

export default HeaderLogin;
