import './HeaderRight.scss';
import { HeaderLogin } from 'components/base/HeaderLogin/HeaderLogin';
import { HeaderNotLogin } from 'components/base/HeaderNotLogin/HeaderNotLogin';

type HeaderRightProps = {
  isSession?: boolean;
  onClickLogout(): Promise<void>;
};

const HeaderRight = ({ isSession, onClickLogout }: HeaderRightProps) => {
  return (
    <div className='HeaderRight'>{!isSession ? <HeaderNotLogin /> : <HeaderLogin onClickLogout={onClickLogout} />}</div>
  );
};

export default HeaderRight;
