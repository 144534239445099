import { Header } from 'components/base/Header';
import { Template } from 'components/base/Template/Template';
import './NotFoundPage.scss';

export const NotFoundPage = () => {
  return (
    <Template header={<Header />}>
      <div className='NotFoundPage'>
        <p>NotFound 404</p>
        <p>페이지를 찾을 수 없습니다.</p>
      </div>
    </Template>
  );
};
