import { Footer } from 'components/base/Footer/Footer';
import { Header } from 'components/base/Header';
import { Template } from 'components/base/Template/Template';
import { Banner } from 'components/common/Banner';
import { Download } from 'components/download/Download/Download';
import { DownloadSpec } from 'components/download/DownloadSpec/DownloadSpec';

export const DownloadPage = () => {
  return (
    <Template header={<Header />} footer={<Footer />}>
      <Banner bannerText={'다운로드'} illust={'/images/download/2.png'} />
      <Download />
      <DownloadSpec />
    </Template>
  );
};
