import { useState } from 'react';

export default function useShowVideo() {
  const [isShowVideo, setIsShowVideo] = useState(false);

  const on = () => {
    setIsShowVideo(true);
  };

  const off = () => {
    setIsShowVideo(false);
  };
  return {
    isShowVideo,
    on,
    off,
  };
}
