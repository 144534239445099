import axios, { AxiosInstance } from 'axios';

const API_URI = 'https://api.vrware.us/api/school';
const LOCAL_API_URI = 'http://localhost:8001/api/school';

let Axios: AxiosInstance;

switch (process.env.NODE_ENV) {
  case 'production':
    Axios = axios.create({
      baseURL: API_URI,
      timeout: 60000,
      headers: {
        clientId: 'JWPU0SBJPHKMZS9YJFNB',
        clientSecretKey: 'KJIKapzeWvyIviZm5wRKMYv86k7tVaBdE8n9TcWN',
      },
    });
    break;
  case 'development':
    Axios = axios.create({
      baseURL: LOCAL_API_URI,
      timeout: 60000,
    });
    break;
  default:
    throw new Error("process.env.NODE_ENV must be either 'production' or 'development' to use this module");
}

export default Axios;
