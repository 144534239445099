import './MainBanner.scss';

type MainBannerProps = {
  on(): void;
};

export const MainBanner = ({ on }: MainBannerProps) => {
  return (
    <div className='MainBanner'>
      <div style={{ backgroundImage: `url('/images/main/bg.png')` }} className='main-banner-bg'>
        <h1 className='banner-title'>3D 캐릭터와 함께 만드는 스토리북 저작 솔루션</h1>
        {/* <a href='#'> */}
        <div className='banner-watch-btn' onClick={on}>
          Watch Now
        </div>
        {/* </a> */}
      </div>
    </div>
  );
};
