import './DownloadSpec.scss';

export const DownloadSpec = () => {
  return (
    <div className='DownloadSpec'>
      <div className='download-headline'>
        <p>시스템 최소/권장사양 안내</p>
      </div>
      <div className='download-spec-table-wrap'>
        <DownloadSpecTable>
          <DownloadSpecHead />
          <tbody>
            <DownloadSpecCPU />
            <DownloadSpecRAM />
            <DownloadSpecVGA />
            <DownloadSpecHDD />
            <DownloadSpecOS />
          </tbody>
        </DownloadSpecTable>
      </div>
    </div>
  );
};

const DownloadSpecTable = ({ children }: { children: React.ReactNode }) => {
  return <table className='download-spec-table'>{children}</table>;
};

const DownloadSpecHead = () => {
  return (
    <thead>
      <tr className='download-spec-head'>
        <th className='download-col-first'>구분</th>
        <th className='download-col-rest'>권장사양</th>
      </tr>
    </thead>
  );
};

const DownloadSpecCPU = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>프로세서(CPU)</td>
      <td className='download-col-rest'>듀얼코어 이상의 3.10GHz 이상 지원</td>
    </tr>
  );
};

const DownloadSpecRAM = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>메모리(RAM)</td>
      <td className='download-col-rest'>8 GB or higher</td>
    </tr>
  );
};

const DownloadSpecVGA = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>그래픽카드(VGA)</td>
      <td className='download-col-rest'>GTX 760 or higher</td>
    </tr>
  );
};

const DownloadSpecHDD = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>하드디스크(HDD)</td>
      <td className='download-col-rest'>20 GB 이상의 하드 드라이브 여유 공간</td>
    </tr>
  );
};

const DownloadSpecOS = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>운영체제(OS)</td>
      <td className='download-col-rest'>Windows® 8 / Windows® 10 64-bit or higher</td>
    </tr>
  );
};
