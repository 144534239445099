import './IntroVideo.scss';
import ReactPlayer from 'react-player';

type IntroVideoProps = {
  isShowVideo: boolean;
  off(): void;
};

export const IntroVideo = ({ isShowVideo, off }: IntroVideoProps) => {
  if (!isShowVideo) return null;
  return (
    <div className='IntroVideo' onClick={off}>
      <div className='IntroVideo-inner'>
        {/* <iframe src="https://www.youtube.com/embed/gEllR0SEjhQ" frameborder="0" /> */}
        <ReactPlayer url='https://youtu.be/yTP6G-sQSXI' width='100%' height='100%' controls={true} />
      </div>
    </div>
  );
};
