import { useHistory } from 'react-router-dom';
import './Header.scss';
import { HeaderRight } from 'components/base/HeaderRight';
import { HeaderMobileMenu } from 'components/base/HeaderMobileMenu';
import Headroom from 'react-headroom';
import useSession from 'hooks/useSession';
import { AuthApi } from 'libs/api';

const Header = () => {
  const history = useHistory();
  const { isSession, logout } = useSession();

  const onClickLogout = async () => {
    try {
      const authInfo = await AuthApi.delete('/sessions', {
        withCredentials: true,
      });

      if (authInfo.data.status_code === 200) {
        logout();
        history.go(0);
      }
    } catch (error) {
      logout();
      history.go(0);
    }
  };

  const onClickGoToPath = (path: string) => {
    const { pathname } = history.location;
    if (pathname === path) return;

    history.push(path);
  };

  return (
    <Headroom className={history.location.pathname === '/' ? 'headroom-transparent' : ''}>
      <div className='Header'>
        <div className='header-wrap'>
          <div className='logo'>
            <img
              className='storymaker-logo'
              alt='로고'
              src={'/images/base/logo.png'}
              onClick={() => onClickGoToPath('/')}
            />
          </div>
          <div className='nav'>
            <ul>
              <li onClick={() => onClickGoToPath('/download')}>
                <label>다운로드</label>
              </li>
              {/* <li onClick={() => onClickGoToPath('/pricing')}>
                <label>가격 및 결제</label>
              </li> */}
              <li>
                <a href='https://my.vrware.us/cs' target='_sub'>
                  <label>고객센터</label>
                </a>
              </li>
            </ul>
          </div>
          <HeaderRight isSession={isSession} onClickLogout={onClickLogout} />
          <HeaderMobileMenu onClickGoToPath={onClickGoToPath} isSession={isSession} onClickLogout={onClickLogout} />
        </div>
      </div>
    </Headroom>
  );
};

export default Header;
