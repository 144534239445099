import { Footer } from 'components/base/Footer/Footer';
import { Header } from 'components/base/Header';
import { Template } from 'components/base/Template/Template';
import { IntroVideo } from 'components/main/IntroVideo/IntroVideo';
import { MainBanner } from 'components/main/MainBanner/MainBanner';
import useShowVideo from 'hooks/main/useShowVideo';

export const MainPage = () => {
  const { isShowVideo, on, off } = useShowVideo();

  return (
    <Template header={<Header />} footer={<Footer />}>
      <IntroVideo isShowVideo={isShowVideo} off={off} />
      <MainBanner on={on} />
    </Template>
  );
};
