import './Download.scss';
import useSession from 'hooks/useSession';

export const Download = () => {
  const { isSession } = useSession();

  const onClickDownload = (downloadUrl: string): void => {
    if (!isSession) {
      //비로그인 상태라면
      alert('로그인 상태에서만 다운받을 수 있습니다.');
      window.location.href = 'https://my.vrware.us/login';
    } else if (isSession) {
      //로그인 상태라면
      window.location.href = downloadUrl;
    }
  };

  return (
    <div className='Download'>
      <div className='download-headline'>
        <p>VRWARE StoryMaker 다운로드</p>
      </div>
      <div className='download-wrap'>
        <div className='download-pc'>
          <div className='download-pc-button'>
            <button
              onClick={() =>
                onClickDownload(
                  'https://api-storage.cloud.toast.com/v1/AUTH_cf00d9d72f6a4f36b46d9ec212ba7396/programs/StoryMaker.exe',
                )
              }
            >
              VRWARE StoryMaker 다운로드
              <img src={'/images/download/download.png'} alt='download' />
            </button>
          </div>
          <label className='download-pc-label'>*PC용 다운로드</label>
        </div>
        {/* <div className='download-hmd'>
          <div className='download-hmd-button'>
            <a href='https://play.google.com/store/apps/details?id=com.Globepoint.VRWARE_School' target='_sub'>
              <button>
                VRWARE School HMD 다운로드
                <img src={'/images/download/download.png'} alt='download' />
              </button>
            </a>
          </div>
          <label className='download-hmd-label'>*안드로이드 모바일용 다운로드</label>
        </div> */}
      </div>
    </div>
  );
};
