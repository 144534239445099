import './Banner.scss';

type BannerProps = {
  bannerText: string;
  centerNode?: JSX.Element;
  illust: string;
};

const Banner = ({ bannerText, centerNode, illust }: BannerProps) => {
  return (
    <div className='Banner'>
      <div className='banner-wrap'>
        <div className='banner-text'>{bannerText}</div>
        {centerNode}
        <div className='banner-img'>
          <img src={illust} alt='illust' />
        </div>
      </div>
    </div>
  );
};

export default Banner;
