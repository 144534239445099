// import { useLocation } from 'react-router-dom';

export const HeaderNotLogin = () => {
  // let location = useLocation();
  let loginUrl;

  if (process.env.NODE_ENV === 'development') {
    loginUrl = 'http://localhost:3000';
    // redirectUrl = 'http://localhost:3001' + location.pathname;
  } else if (process.env.NODE_ENV === 'production') {
    loginUrl = 'https://my.vrware.us';
    // redirectUrl = 'https://school.vrware.us' + location.pathname;
  }

  return (
    <ul className='HeaderNotLogin'>
      <li>
        {/* <a href={`${loginUrl}/join?redirectUrl=${redirectUrl}`}> */}
        <a href={`${loginUrl}/join`}>
          <button className='login-button'>회원가입</button>
        </a>
      </li>
      <li>
        {/* <a href={`${loginUrl}/login?redirectUrl=${redirectUrl}`}> */}
        <a href={`${loginUrl}/login`}>
          <button className='login-button'>로그인</button>
        </a>
      </li>
    </ul>
  );
};
