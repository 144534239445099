import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import reportWebVitals from 'reportWebVitals';
import Root from 'Root';

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
