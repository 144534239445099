import './Footer.scss';

export const Footer = () => {
  return (
    <div id='test' className='footer-wrap'>
      <div className='footer-box'>
        <div className='gp-logo'>
          <img src={'/images/footer/footer_logo.png'} alt='globepoint' />
        </div>
        <div className='footer-center'>
          <div className='footer-center-text'>
            {/* <div className='footer-center-top'>
              <Link to='/policy/privacy'>
                <p>개인정보처리방침이용약관</p>
              </Link>
              <p>|</p>
              <Link to='/policy/service'>
                <p>이용약관</p>
              </Link>
            </div> */}
            <div className='footer-center-bottom'>
              <div>
                <p>(주)글로브포인트 사업자등록번호 : 107-87-72034</p>
                <p>대표이사 : 조상용</p>
              </div>
              <p>주소 : 경기도 고양시 덕양구 삼원로 83, 1111호 (원흥동, 광양프런티어벨리 6차)</p>
              <div>
                <p>전화 : 031-911-0609</p>
                <p>이메일 :gpsales@globepoint.co.kr</p>
              </div>
              <p className='copyrights'>Copyrights ⓒ 2019 Globepoint Inc. All rights reserved.</p>
            </div>
          </div>
        </div>
        <div className='footer-right'>
          <div className='footer-cs'>
            <p>고객센터</p>
          </div>
          <div className='footer-company-ph'>
            <p>031-911-0609</p>
          </div>
          <div className='footer-company-wh'>
            <p>평일 10:00~18:00 (점심시간 12:30~13:30)</p>
            <p>국/공휴일 휴무</p>
          </div>
          <div className='footer-company-sns'>
            <FooterSNS />
          </div>
        </div>
      </div>
      <FooterBottomSNS />
    </div>
  );
};

const FooterSNS = () => {
  return (
    <ul>
      <li>
        <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
          <img src={'/images/footer/youtube.png'} alt='youtube' />
        </a>
      </li>
      <li>
        <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
          <img src={'/images/footer/facebook.png'} alt='facebook' />
        </a>
      </li>
      <li>
        <a href='https://twitter.com/GlobepointK' target='_sub'>
          <img src={'/images/footer/twitter.png'} alt='twitter' />
        </a>
      </li>
      <li>
        <a href='http://blog.vrware.co.kr/' target='_sub'>
          <img src={'/images/footer/blog.png'} alt='blog' />
        </a>
      </li>
    </ul>
  );
};

const FooterBottomSNS = () => {
  return (
    <div className='footer-bottom-sns'>
      <ul>
        <li>
          <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
            <img src={'/images/footer/youtube.png'} alt='youtube' />
          </a>
        </li>
        <li>
          <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
            <img src={'/images/footer/facebook.png'} alt='facebook' />
          </a>
        </li>
        <li>
          <a href='https://twitter.com/GlobepointK' target='_sub'>
            <img src={'/images/footer/twitter.png'} alt='twitter' />
          </a>
        </li>
        <li>
          <a href='http://blog.vrware.co.kr/' target='_sub'>
            <img src={'/images/footer/blog.png'} alt='blog' />
          </a>
        </li>
      </ul>
    </div>
  );
};
